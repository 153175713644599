<!-- 联络人 -->
<template>
  <div class="contact_person">
    <div class="row">
      <div class="search_right">
        <el-input
          placeholder="请输入姓名/手机号"
          v-model="queryInfo.keys"
          class="input-with-select"
          style="width: 440px"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </div>
      <el-button type="primary" @click="add" :disabled="addBtnDisabled">新增</el-button>
    </div>
    <div class="table">
      <el-table
        ref="contactPersonTable"
        :data="list"
        style="width: 100%"
        :header-cell-style="{ background: '#F8F9FB' }"
        @sort-change="sortChanged"
      >
        <el-table-column label="编号" min-width="100" align="center">
          <template v-slot="{ row }">
            <div>G00{{ row.id }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="liaisonsName" label="姓名" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="positionPhone" label="手机号" min-width="120" align="center">
        </el-table-column>
        <el-table-column prop="liaisonsPosition" label="职位" min-width="100" align="center">
        </el-table-column>
       
        <el-table-column prop="createTime" label="添加时间" min-width="140" align="center" sortable>
        </el-table-column>
        <el-table-column label="操作" min-width="140" align="center">
          <template v-slot="{ row }">
            <div class="span_btn_list">
              <span :class="row.notFreeze ? 'color2' : 'color1'" @click="edit(row)">编辑</span>
              <span class="color3" v-if="!row.notFreeze" @click="setFreeze(row.id, row.notFreeze)"
                >冻结</span
              >
              <span class="color3" v-else @click="setFreeze(row.id)">解冻</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 添加联络人弹窗 -->
    <el-dialog
      :title="echo === 0 ? '新增联络人' : '编辑联络人'"
      :visible.sync="addDialogVisible"
      @closed="addDialogClosed"
      :close-on-click-modal="false"
      width="600px"
    >
      <div class="dialog_box">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="姓名：">
            <div class="input_box">
              <el-input v-model="form.liaisonsName"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="职位：">
            <div class="input_box">
              <el-input v-model="form.liaisonsPosition"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="手机号：">
            <div class="input_box">
              <el-input v-model="form.positionPhone"></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog_footer">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save" :disabled="saveBtnDisabled">保存</el-button>
          <el-button @click="addDialogClosed">取 消</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchContactPerson,
  searchContactPersonById,
  notFreezeContactPerson,
  saveContactPerson,
  updateContactPerson
} from '@/api/enterpriseCustomer.js'

const defaultQueryInfo = Object.freeze({
  companyId: 0, // 企业客户编号
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 默认传1 2->时间升序;3->时间降序
  keys: '' // 姓名/手机号
})
const defaultForm = Object.freeze({
  companyId: 0, // 企业客户编号
  liaisonsName: '', // 联络人姓名
  liaisonsPosition: '', // 联络人职位
  positionPhone: '' // 联络人电话
})

export default {
  name: 'ContactPerson',
  components: {},
  props: {
    id: {
      type: Number
    }
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          console.log(val)
          this.queryInfo.companyId = val
          this.form.companyId = val
          this.search()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      count: {
        setFreeze: 0
      },
      addBtnDisabled: false,
      saveBtnDisabled: false,
      echo: 0, // 0是新增，1是编辑
      form: { ...defaultForm }, //表单
      addDialogVisible: false, // 添加联络人弹窗
      queryInfo: { ...defaultQueryInfo }, // 联络人查询一览
      list: [], // 联络人列表
      total: 0 // 联络人总条数
    }
  },
  created() {
    // this.queryInfo.companyId = this.id
  },
  methods: {
    async search() {
      const res = await searchContactPerson(this.queryInfo)
      if (res.resultCode === 200) {
        this.list = res.data.list
        this.total = res.data.total
      } else {
        this.$message.warning('获取联络人信息失败!')
      }
    },
    sortChanged(val) {
      if (val.order === 'ascending') {
        this.queryInfo.orders = 2
        this.search()
      } else {
        this.queryInfo.orders = 3
        this.search()
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    // 新增
    add() {
      this.addBtnDisabled = true
      this.echo = 0
      this.form = { ...defaultForm }
      this.addDialogVisible = true
    },
    // 编辑
    async edit(row) {
      if (row.notFreeze) {
        if (this.count.setFreeze === 0) {
          this.count.setFreeze = 1
          this.$message.warning('此用户已被冻结!')
        }
        setTimeout(() => {
          this.count.setFreeze = 0
        }, 5000)
      } else {
        const id = row.id
        const res = await searchContactPersonById({ id })
        if (res.resultCode === 200) {
          this.echo = 1
          this.form = res.data
          this.addDialogVisible = true
        } else {
          this.$message.warning('获取联络人信息失败!')
        }
      }
    },
    async save() {
      this.saveBtnDisabled = true
      this.form.companyId = this.id
      const fn = this.echo === 0 ? saveContactPerson : updateContactPerson
      const str = this.echo === 0 ? '新增联络人' : '修改联络人信息'
      const res = await fn(this.form)
      if (res.resultCode === 200) {
        this.saveBtnDisabled = false
        // this.form = res.data
        this.addDialogClosed()
        this.$message.success(str + '成功!')
        this.$emit('childMethods')
      } else {
        this.saveBtnDisabled = false
        this.$message.warning(str + '失败!')
      }
    },
    addDialogClosed() {
      this.addDialogVisible = false
      this.saveBtnDisabled = false
      this.addBtnDisabled = false
      this.search()
    },
    // 冻结、解冻
    async setFreeze(id, bol) {
      let val = `${bol ? '解冻' : '冻结'}`
      const res = await notFreezeContactPerson({ id })
      if (res.resultCode === 200) {
        this.$message.success(val + '成功!')
        this.search()
      } else {
        this.$message.warning(val + '失败!')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.contact_person {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 340px);
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
  background-color: #fff;
  .table {
   height: calc(100vh - 390px);
    overflow-y: auto;
    margin-top: 10px;
    .span_btn_list {
      span {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}
.input_box {
  width: 80%;
}
.dialog_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
  padding: 20px;
}
.new_page {
  margin: 10px auto;
  width: 100%;
  text-align: center;
}
.search_right {
  // width: 560px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.color1 {
  color: #448aff;
}
.color2 {
  color: #9b9a9a;
}
.color3 {
  color: #fd5469;
}
</style>
