<!-- 企业详情 -->
<template>
  <div class="enterprise_details">
    <div class="top_card">
      <div class="show_manager-info" v-show="showManagerInfo">
        <div class="avatar_box">
          <img :src="userInfo.customerHead" alt="" />
        </div>
        <div class="manager_name">{{ userInfo.customerName }}</div>
        <div class="row">
          <div class="icon_box">
            <i class="iconfont icon-jr-icon-job color2"></i>
          </div>
          <span>职位：{{ userInfo.customerPosition }}</span>
          <span class="color2">|</span>
          <span>{{ userInfo.sex === 0 ? '男' : '女' }}</span>
          <div class="icon_box">
            <i v-if="userInfo.sex === 1" class="iconfont icon-jr-icon-Woman color4"></i>
            <i v-else class="iconfont icon-jr-icon-Man color5"></i>
          </div>
        </div>
        <div class="row">
          <div class="icon_box">
            <i class="iconfont icon-jr-icon-phone color2"></i>
          </div>
          <span>手机号：{{ userInfo.customerPhone }}</span>
        </div>
        <div class="row">
          <div class="icon_box">
            <i class="iconfont icon-jr-icon-area color2"></i>
          </div>
          <div class="txt_row">
            <span>所在区域：</span>
            <!-- <span v-if="userInfo.provinces">{{ userInfo.provinces + '-' }}</span> -->
            <span v-if="userInfo.city">{{ userInfo.city + '-' }}</span>
            <span v-if="userInfo.district">{{ userInfo.district }}</span>
          </div>
        </div>
      </div>
      <div class="update_time">
        <i class="iconfont icon-jr-icon-refresh1 color1"></i>
        <span class="color1">最近服务更新时间：</span>
        <span class="color1">2023-04-13 10:56:06</span>
      </div>
      <div class="img_box">
        <img v-if="form.companyLogo" :src="form.companyLogo" alt="" />
        <img v-else src="@/assets/image/enterprise-default-logo.png" alt="" />
      </div>
      <div class="info_box">
        <div class="row">
          <div class="enterprise_name">{{ form.companyFullName }}</div>
          <div class="type_tag" :class="getEnterpriseScale(form.enterpriseScale)">
            {{ form.enterpriseScale }}
          </div>
        </div>
        <div class="row">
          <el-popover placement="right" width="300" trigger="hover">
            <div class="tag_list">
              <div class="tag_item" v-for="(i1, i2) in form.companyLabelList" :key="i2">
                <div>{{ i1 }}</div>
              </div>
            </div>

            <div slot="reference">
              <div v-if="form.companyLabelList" class="tag_lists">
                <div
                  class="tag_item"
                  v-for="(i1, i2) in form.companyLabelList.slice(0, 4)"
                  :key="i2"
                >
                  <div v-if="i2 < 4">{{ i1 }}</div>
                </div>
                <span v-if="form.companyLabelList.length > 4">...</span>
              </div>
            </div>
          </el-popover>
          <!-- <div class="tag_list">
            <div class="tag_item" v-for="(i1, i2) in form.companyLabelList" :key="i2">
              <div v-if="i2 < 4">{{ i1 }}</div>
            </div>
            <div class="show_more" v-if="form.companyLabelList">
              <div v-if="form.companyLabelList.length > 4">
                {{ form.companyLabelList.length }}...
              </div>
            </div>
          </div> -->
        </div>
        <div class="row">
          <span>{{ form.legalPerson }}</span>
          <span>{{ form.companyPhone }}</span>
          <span>{{ form.city }}<span v-if="form.district ">-{{ form.district }}</span></span>
          <span class="website" @click="toOfficialWebsite(form.officialWebsite)">{{
            form.officialWebsite
          }}</span>
        </div>
      </div>
      <div class="card_right">
        <div class="btn_list">
          <!-- <el-button @click="edit" plain icon="el-icon-edit-outline">修改信息</el-button> -->
        </div>
        <div class="row" v-if="!customerId">
          <div class="mini_img_box">
            <img src="../../../assets/image/wait-set-manager.png" alt="" />
          </div>
          <span class="wait_set color3" @click="showSetManagerDialog">待分配</span>
        </div>
        <div
          class="row"
          v-else
          @mouseenter="showManagerInfo = true"
          @mouseleave="showManagerInfo = false"
        >
          <div>客户经理：</div>
          <div class="mini_img_box">
            <img :src="userInfo.customerHead" alt="" />
          </div>
          <div>{{ userInfo.customerName }}</div>
        </div>
      </div>
    </div>
    <div>
      <div class="tabs">
        <div class="item" :class="activeName === 0 ? 'active_item' : ''" @click="toEnterpriseInfo">
          基本信息
        </div>
        <div class="item" :class="activeName === 1 ? 'active_item' : ''" @click="toContactPerson">
          联络人（{{ num.contactPerson ? num.contactPerson : 0 }}）
        </div>
        <div class="item" :class="activeName === 4 ? 'active_item' : ''" @click="toServiceLog">
          服务记录（{{ num.serviceLog ? num.serviceLog : 0 }}）
        </div>
        <div
          class="item"
          :class="activeName === 2 ? 'active_item' : ''"
          @click="toDiagnosticLogout"
        >
          场景信息（{{ num.sceneNum ? num.sceneNum : 0 }}）
        </div>
        <div class="item" :class="activeName === 3 ? 'active_item' : ''" @click="toCustomerDemand">
          潜在线索（{{ num.latentClueNum ? num.latentClueNum : 0 }}）
        </div>

        <div
          class="item"
          :class="activeName === 5 ? 'active_item' : ''"
          @click="toServiceSuggestion"
        >
          售前建议（{{ num.serviceSuggestion ? num.serviceSuggestion : 0 }}）
        </div>
      </div>
      <div class="container">
        <div v-if="activeName === 0" class="enterprise_info">
          <div class="descriptions_flex">
            <el-descriptions title="" :column="3" border>
              <el-descriptions-item label="企业头像：" :contentStyle="{ 'text-align': 'center' }">
                <div class="row_content">
                  <img
                    v-if="form.companyLogo"
                    width="60"
                    height="60"
                    :src="form.companyLogo"
                    alt=""
                  />
                  <img
                    v-else
                    width="60"
                    height="60"
                    src="@/assets/image/enterprise-default-logo.png"
                    alt=""
                  />
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="企业名称：" :contentStyle="{ 'text-align': 'center' }">
                {{ form.companyName ? form.companyName : '暂无' }}
              </el-descriptions-item>
              <el-descriptions-item label="企业全称：" :contentStyle="{ 'text-align': 'center' }">
                {{ form.companyFullName ? form.companyFullName : '暂无' }}
              </el-descriptions-item>
              <el-descriptions-item label="所在地区：" :contentStyle="{ 'text-align': 'center' }">
                <span v-if="form.city"
                    >{{
                      form.city ? form.city : '暂无'
                    }}<span v-if="form.district ">-{{ form.district }}</span></span
                  >
              </el-descriptions-item>
              <el-descriptions-item label="法人：" :contentStyle="{ 'text-align': 'center' }">
                {{ form.legalPerson ? form.legalPerson : '暂无' }}
              </el-descriptions-item>
              <el-descriptions-item label="成立时间：" :contentStyle="{ 'text-align': 'center' }">
                {{ form.establishedTime ? form.establishedTime : '暂无' }}
              </el-descriptions-item>
              <el-descriptions-item label="企业简介：" :contentStyle="{ 'text-align': 'left' }">
                {{ form.enterpriseIntro ? form.enterpriseIntro : '暂无' }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <!-- <div class="item_card">
            <div class="tittle">基本信息</div>
            <div class="row">
              <div class="label">企业头像</div>
              <div class="row_content">
                <img v-if="form.companyLogo" :src="form.companyLogo" alt="" />
                <img v-else src="@/assets/image/enterprise-default-logo.png" alt="" />
              </div>
            </div>
            <div class="row">
              <div class="label">企业名称</div>
              <div class="row_content">{{ form.companyName ? form.companyName : '暂无' }}</div>
            </div>
            <div class="row">
              <div class="label">企业全称</div>
              <div class="row_content">
                {{ form.companyFullName ? form.companyFullName : '暂无' }}
              </div>
            </div>
            <div class="row">
              <div class="label">所在地区</div>
              <div class="row_content">
                <span v-if="form.city"
                  >{{ form.province ? form.province : '暂无' }}-{{
                    form.city ? form.city : '暂无'
                  }}</span
                >
              </div>
            </div>
            <div class="row">
              <div class="label">法人</div>
              <div class="row_content">{{ form.legalPerson ? form.legalPerson : '暂无' }}</div>
            </div>
            <div class="row">
              <div class="label">成立时间</div>
              <div class="row_content">
                {{ form.establishedTime ? form.establishedTime : '暂无' }}
              </div>
            </div>
            <div class="row">
              <div class="label">企业简介</div>
              <div class="row_content">
                {{ form.enterpriseIntro ? form.enterpriseIntro : '暂无' }}
              </div>
            </div>
          </div> -->
          <!-- <div class="item_card">
            <div class="tittle">
              股权信息
              <span>（{{ count.equity }}）</span>
            </div>
            <Equity :companyId="id" @getCount="getEquityCount" />
          </div>
          <div class="item_card">
            <div class="tittle">
              项目招标
              <span>（{{ count.projectBiding }}）</span>
            </div>
            <ProjectBiding :companyId="id" @getCount="getProjectBidingCount" />
          </div>
          <div class="item_card">
            <div class="tittle">
              招聘信息<span>（{{ count.recruit }}）</span>
            </div>
            <Recruit :companyId="id" @getCount="getRecruitCount" />
          </div>
          <div class="last_item_card">
            <div class="tittle">
              分公司信息<span>（{{ count.filial }}）</span>
            </div>
            <Filial :companyId="id" @getCount="getFilialCount" />
          </div> -->
        </div>
        <ContactPerson v-else-if="activeName === 1" @childMethods="childMethods" :id="id" />
        <DiagnosticLogout
          v-else-if="activeName === 2"
          :id="id"
          :companyFullName="form.companyFullName"
        />
        <ServiceSuggestion
          v-else-if="activeName === 3"
          :userInfo="userInfo"
          :customerId="customerId"
          :orgCompanyId="orgCompanyId"
          :id="id"
        />
        <!-- <ServiceLog v-else-if="activeName === 4" :id="id" /> -->
        <CustomerDemand v-else-if="activeName === 5" :orgCompanyId="orgCompanyId" :id="id" />
        <ServiceLog
          v-else-if="activeName === 4"
          :customerId="customerId"
          :orgCompanyId="orgCompanyId"
          :id="id"
        />
        <div class="card_footer">
          <el-button type="primary" @click="back">返回上一级</el-button>
        </div>
      </div>
    </div>
    <!-- 分配客户经理弹窗 -->
    <el-dialog
      title="分配客户经理"
      :visible.sync="setManagerDialogVisible"
      @closed="setManagerDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_box">
        <div class="row">
          <city-select
            ref="child"
            :province="managerQueryInfo.provinces"
            :city="managerQueryInfo.city"
            :district="managerQueryInfo.district"
            @searchProvince="searchProvinceManager"
            @searchCity="searchCityManager"
            @searchDistrict="searchDistrictManager"
          />
        </div>
        <div class="row">
          <div class="search_right">
            <el-input
              placeholder="请输入姓名/电话"
              v-model="managerQueryInfo.keys"
              class="input-with-select"
              style="width: 440px"
            >
              <el-button slot="append" @click="searchCustomerManager">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div class="table">
          <el-table
            ref="managerTable"
            :data="managerTableList"
            style="width: 100%"
            :header-cell-style="{ background: '#F8F9FB' }"
            @select="handleSelect"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="用户头像" min-width="120" align="center">
              <template v-slot="{ row }">
                <img :src="row.customerHead" alt="" class="customer_head" />
              </template>
            </el-table-column>
            <el-table-column prop="customerName" label="姓名" min-width="120"> </el-table-column>
            <el-table-column label="所在地区" min-width="120">
              <template v-slot="{ row }">
                <span>{{ row.provinces }}</span>
                <span>{{ row.city }}</span>
                <span>{{ row.district }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="customerPhone" label="手机号" min-width="120"> </el-table-column>
            <el-table-column prop="affiliatedEnterprise" label="关联企业" min-width="120" sortable>
            </el-table-column>
            <el-table-column prop="loginTime" label="最近登录时间" min-width="140" sortable>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="managerQueryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="managerQueryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="managerTableTotal"
          @size-change="handleSizeChangeManager"
          @current-change="handleCurrentChangeManager"
        />
      </div>
      <div class="dialog_footer">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSetManager">保存</el-button>
          <el-button @click="setManagerDialogClosed">取 消</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 编辑企业信息弹窗 -->
    <AddEnterprise ref="addRef" :echo="echo" @search="search" />
  </div>
</template>

<script>
import AddEnterprise from './addenterprise.vue'
import CitySelect from '@/components/CitySelect.vue'
import {
  listById,
  update,
  notAllocate,
  companyTopListById,
  searchCustomerManager,
  searchCustomerManagerById
} from '@/api/enterpriseCustomer.js'
import ContactPerson from './contactPerson.vue'
import DiagnosticLogout from './diagnosticLogout.vue'
import CustomerDemand from './customerDemand.vue'
import ServiceLog from './serviceLog.vue'
import ServiceSuggestion from './serviceSuggestion.vue'
// import Equity from './children/equity.vue'
// import ProjectBiding from './children/projectBiding.vue'
// import Filial from './children/filial.vue'
// import Recruit from './children/recruit.vue'
// import CitySelect from '@/components/CitySelect.vue'

const defaultForm = Object.freeze({
  city: '', // 城市
  customerHead: '', // 头像
  customerName: '', // 名字
  customerPhone: '', // 电话
  customerPosition: '', // 职位
  dateOfBirth: '', // 出生年月
  district: '', // 区/县
  notFreeze: true, // 是否冻结(默认0 0->未冻结;1->已冻结)
  provinces: '', // 省
  sex: 0 // 性别(默认0 0->男;1->女)
})
const defaultManagerQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 默认传1 2->更新时间降序;3->更新时间升序
  keys: '' //  姓名/电话
})
export default {
  name: 'EnterpriseDetails',
  components: {
    ContactPerson,
    DiagnosticLogout,
    CustomerDemand,
    ServiceLog,
    ServiceSuggestion,
    CitySelect,
    AddEnterprise
    // Equity,
    // ProjectBiding,
    // Filial,
    // Recruit
  },
  data() {
    return {
      count: {
        equity: 0, // 企业股权信息
        projectBiding: 0, // 项目招标
        filial: 0, // 企业分公司信息
        recruit: 0 // 企业招聘信息
      }, // 天眼查统计数

      userInfo: {}, // 客户经理信息
      echo: 0, // 0是新增，1是删除
      showManagerInfo: false, // 控制是否展示客户经理信息box
      customerId: null, // 客户经理id
      companyIdList: [], // 企业id  分配客户经理传参
      setManagerDialogVisible: false, // 分配客户经理弹窗
      managerQueryInfo: { ...defaultManagerQueryInfo }, // 客户经理查询
      managerTableList: [], // 客户经理列表
      managerTableTotal: 0, // 客户经理总条数
      id: null, // 企业id
      orgCompanyId: null,
      activeName: 1, //激活的tabs
      form: { ...defaultForm }, // 表单
      num: {
        contactPerson: 0, // 联络人
        diagnosticLogout: 0, // 诊断记录
        customerDemand: 0, // 客户需求
        serviceLog: 0, // 服务记录
        serviceSuggestion: 0 // 服务建议
      } // tabs统计数
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = Number(this.$route.query.id)
      this.orgCompanyId = this.$route.query.orgCompanyId
      this.activeName = Number(this.$route.query.val)
      this.customerId = Number(this.$route.query.customerId)
    }
    this.search()
  },
  methods: {
    // 返回企业类型标签绑定的class
    getEnterpriseScale(val) {
      if (val === '大型') {
        return 'bc_color4'
      } else if (val === '中型') {
        return 'bc_color5'
      } else if (val === '小型') {
        return 'bc_color6'
      } else if (val === '微型') {
        return 'bc_color7'
      }
    },
    childMethods() {
      this.search()
    },
    async search() {
      const res = await listById({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
        this.customerId = res.data.customerId
        if (this.customerId) {
          this.searchCustomerManagerById(this.customerId)
        }
      } else {
        this.$message.warning('获取企业信息失败!')
      }
      const result = await companyTopListById({ id: this.id })
      if (result.resultCode === 200) {
        this.num = result.data
      } else {
        this.$message.warning('获取企业信息失败!')
      }
    },
    // 获取企业股权信息total
    getEquityCount(val) {
      this.count.equity = val
    },
    // 获取企业项目招标total
    getProjectBidingCount(val) {
      this.count.projectBiding = val
    },
    // 获取企业分公司信息total
    getFilialCount(val) {
      this.count.filial = val
    },
    // 获取企业招聘信息total
    getRecruitCount(val) {
      this.count.recruit = val
    },
    // 查询客户经理详情
    async searchCustomerManagerById(id) {
      const res = await searchCustomerManagerById({ id })
      if (res.resultCode === 200) {
        this.userInfo = res.data
      } else {
        this.$message.warning('获取企业信息失败!')
      }
    },
    // 编辑企业
    async edit() {
      this.echo = 1
      this.$refs.addRef.addDialogVisible = true
      this.$refs.addRef.id = this.id
    },
    // 打开分配客户经理弹窗
    showSetManagerDialog() {
      this.companyIdList.push(this.id)
      this.managerQueryInfo = { ...defaultManagerQueryInfo }
      this.searchCustomerManager()
      this.setManagerDialogVisible = true
    },
    // 查询客户经理
    async searchCustomerManager() {
      const res = await searchCustomerManager(this.managerQueryInfo)
      if (res.resultCode === 200) {
        this.managerTableList = res.data.list
        this.managerTableTotal = res.data.total
        this.$message.success('获取客户经理信息成功!')
      } else {
        this.$message.warning('获取客户经理信息失败!')
      }
    },
    //监听pagesize变化  分配客户经理弹窗
    handleSizeChangeManager(newSize) {
      this.managerQueryInfo.pageSize = newSize
      this.searchCustomerManager()
    },
    //监听页码变化 分配客户经理弹窗
    handleCurrentChangeManager(newCurrent) {
      this.managerQueryInfo.pageNum = newCurrent
      this.searchCustomerManager()
    },
    //返回省  分配客户经理弹窗
    searchProvinceManager(val) {
      this.managerQueryInfo.provinces = val
      this.searchCustomerManager()
    },
    //返回城市 分配客户经理弹窗
    searchCityManager(val) {
      this.managerQueryInfo.city = val
      this.searchCustomerManager()
    },
    //返回区/县 分配客户经理弹窗
    searchDistrictManager(val) {
      this.managerQueryInfo.district = val
      this.searchCustomerManager()
    },
    // 选中table项 分配客户经理弹窗
    handleSelect(selection, val) {
      //只能选择一行，选择其他，清除上一行
      if (selection.length > 1) {
        let del_row = selection.shift()
        this.$refs.managerTable.toggleRowSelection(del_row, false) //设置这一行取消选中
      }
      this.customerId = val.id
    },
    // 关闭分配客户经理弹窗
    setManagerDialogClosed() {
      this.companyIdList = []
      this.setManagerDialogVisible = false
    },
    //保存按钮
    async saveSetManager() {
      let params = {
        companyIdList: this.companyIdList,
        customerId: this.customerId
      }
      const res = await notAllocate(params)
      if (res.resultCode === 200) {
        this.$message.success('分配客户经理成功!')
        this.setManagerDialogVisible = false
        this.companyIdList = []
        this.search()
      } else {
        this.$message.warning('分配客户经理失败!')
      }
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 跳转官网
    toOfficialWebsite(val) {
      window.open(val)
    },
    // 企业信息
    toEnterpriseInfo() {
      this.activeName = 0
    },
    // 联络人
    toContactPerson() {
      this.activeName = 1
    },
    // 诊断记录
    toDiagnosticLogout() {
      this.activeName = 2
    },
    // 客户需求
    toCustomerDemand() {
      this.activeName = 3
    },
    // 服务记录
    toServiceLog() {
      this.activeName = 4
    },
    // 服务建议
    toServiceSuggestion() {
      this.activeName = 5
    }
  }
}
</script>

<style lang="less" scoped>
.row {
  display: flex;
  align-items: center;
  margin-bottom: 5px !important;
  .icon_box {
    width: 16px;
    height: 16px;
    // border: #7c7f8e 1px dotted;
    margin-right: 5px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
}
.search_right {
  width: 460px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.btn_list {
  margin-left: auto;
  margin-right: 20px;
}
.enterprise_details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}
.tag_list {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  .tag_item {
    margin: 3px;
    font-size: 10px;
    color: #4e93fb;
    border: #559eff 1px solid;
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
  }
  .show_more {
    position: relative;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
  }
}
.tag_lists {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  .tag_item {
    margin: 3px;
    font-size: 10px;
    color: #4e93fb;
    border: #559eff 1px solid;
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
  }
  .show_more {
    position: relative;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
  }
}
.top_card {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;
  .show_manager-info {
    min-width: 300px;
    display: flex;
    flex-flow: column;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.17);
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    right: 40px;
    transform: translateY(100% + 10px);
    z-index: 999;
    .avatar_box {
      margin: 10px auto;
      width: 64px;
      height: 64px;
      img {
        width: 64px;
        height: 64px;
      }
    }
    .manager_name {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #333;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 5px;
    }
    .row span {
      margin-right: 10px;
    }
    .row .txt_row {
      margin-right: 20px;
      span {
        margin-right: 0;
      }
    }
  }
  .update_time {
    position: absolute;
    bottom: 10px;
    right: 20px;
    i {
      margin-right: 5px;
    }
    span {
      font-size: 12px;
      color: #999;
    }
  }
  .img_box {
    width: 76px;
    height: 76px;
    margin-right: 10px;
    // border: #7c7f8e 1px dotted;
    img {
      width: 76px;
      height: 76px;
    }
  }
  .info_box {
    display: flex;
    justify-content: center;
    flex-flow: column;
    .enterprise_name {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #000;
    }
    .type_tag {
      font-size: 10px;
      color: #fff;
      text-align: center;
      margin-left: 10px;
      padding: 5px;
      background-color: #559eff;
      border-radius: 2px;
    }

    .row span {
      font-size: 14px;
      margin-right: 10px;
    }
    .website {
      cursor: pointer;
      font-size: 14px;
      color: #448aff;
    }
    .website:hover {
      text-decoration: underline;
    }
  }
  .card_right {
    margin-left: auto;
    margin-right: 80px;
    display: flex;
    ::v-deep .el-button {
      color: #448aff;
      border-color: #448aff;
    }
    .row {
      cursor: pointer;
    }
    .mini_img_box {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin: 0 10px;
      overflow: hidden;
      img {
        width: 36px;
        height: 36px;
      }
    }
    .wait_set {
      cursor: pointer;
      text-decoration: underline;
      font-size: 16px;
    }
  }
}
.tabs {
  display: flex;
  width: 100%;
  padding: 10px 0;
  background-color: #fafafa;
  .item {
    cursor: pointer;
    min-width: 104px;
    text-align: center;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 16px;
    color: #1f2d3d;
    position: relative;
  }
  .active_item:after {
    content: '';
    position: absolute;
    left: 20px;
    top: auto;
    bottom: 0;
    right: 0;
    height: 3px;
    width: 64px;
    border-radius: 8px;
    background-color: #4e93fb;
  }
}
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 12px;
    width: 100px;
    text-align: center;
   
}
.container {
  display: flex;
  flex-flow: column;
  flex: 1;
  width: 100%;
  .enterprise_info {
    width: 100%;
    height: calc(100vh - 340px);
    background: #fff;
    overflow-y: auto;

    .descriptions_flex {
      padding: 20px 100px 20px 20px;
      .row_content {
       
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        display: flex;
        justify-content: center;
      }
    }
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }
    .item_card {
      width: 100%;
      padding: 30px 30px 10px 20px;
      box-sizing: border-box;
      background-color: #fff;
      margin-bottom: 5px;
      .row {
        margin-bottom: 0px;
        .label {
          width: 80px;
          text-align: end;
          font-size: 14px;
          line-height: 20px;
          color: #838383;
          margin-right: 16px;
        }
      }
    }
    .last_item_card {
      width: 100%;
      padding: 30px 30px 10px 20px;
      box-sizing: border-box;
      background-color: #fff;
    }
    .tittle {
      font-size: 20px;
      line-height: 28px;
      color: #000000;
      margin-bottom: 30px;
    }
  }
  .card_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    margin-top: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-top: #f4f4f4 1px solid;
  }
}
.dialog_box {
  display: flex;
  flex-flow: column;
  .table {
    overflow-y: auto;
    height: 300px;
    margin-top: 10px;
    .customer_head {
      width: 42px;
      height: 42px;
    }
  }
}
.dialog_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
  padding: 20px;
}
.color1 {
  color: #999;
}
.color2 {
  color: #dad5d5;
}
.color3 {
  color: #fc8b39;
}
.color4 {
  color: #fd89ff;
}
.color5 {
  color: #0062ff;
}
</style>
